<template>
  <div class="block">
    <!-- 轮播图 -->
    <el-carousel trigger="click" height="840px" :interval="3000">
      <el-carousel-item v-for="item in images" :key="item.id" name="item.id">
        <div class="pic_item">
          <img class="small" :src="item.src" />
          <h3>{{ item.title }}</h3>
          <span class="blockMain animate__animated animate__fadeInUp">{{
            item.main
          }}</span>
        </div>
      </el-carousel-item>
    </el-carousel>

    <!-- 右边二维码 -->
    <!--<div class="code">
      <ul>
        <li v-for="(item, index) in arr" :key="index">
          <img class="codeImg" :src="item.img" alt="" />
          <p class="codeTitle">{{ item.title }}</p>
        </li>
      </ul>
    </div>-->

    <div class="main">
      <!-- 平台特色 -->
      <div class="mainNav">
        <h2 class="mainNavTitle">平台特色</h2>
        <ul class="mainul">
          <li
            v-for="(item, index) in mainList"
            :key="index"
            @click="code(index)"
            :class="oldindex == index ? 'active' : ''"
          >
            <img class="mainImg" :src="item.img" alt="" />
            <p class="mainTitle">{{ item.title }}</p>
          </li>
        </ul>
      </div>
      <div class="feature">
        <div
          class="feature1"
          v-for="(item, index) in featureList"
          :key="index"
          v-show="oldindex == index"
        >
          <img class="featureImg" :src="item.img" alt="" />
          <div class="shade">
            <h3 class="feature1Title">{{ item.title }}</h3>
            <span class="feature1Main">{{ item.main }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="main">
      <!-- 行业解决方案 -->
      <div class="mainNav">
        <h2 class="mainNavTitle">行业解决方案</h2>
        <ul class="mainul1">
          <li
            v-for="(item, index) in print"
            :key="index"
            @click="add = index"
            :class="{ model: add === index }"
          >
            <p class="anTitle">{{ item.title }}</p>
          </li>
        </ul>
      </div>
      <div class="feature">
        <div
          class="print"
          v-for="(item, index) in printList"
          :key="index"
          v-show="add === index"
        >
          <img class="printImg" :src="item.img" alt="" />
          <div class="printBox">
            <img class="printIcon" :src="item.imgs" alt="" />
            <h3 class="printTit">{{ item.title }}</h3>
            <p class="printMain">{{ item.main }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="join">
      <p class="joinTitle">构建开放共赢的救援共享新生态</p>
      <router-link class="btn" to="/login"> 加入我们 </router-link>
    </div>

    <div class="teamwork">
      <span class="teamworkTitle">合作伙伴</span>
      <ul>
        <li v-for="(item, index) in teamworkList" :key="index">
          <img class="teamworkImg" :src="item.img" alt="" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import "animate.css";
export default {
  name: "carousel",
  data() {
    return {
      add: 0,
      oldindex: 0,
      images: [
        {
          src: require("../assets/img/image48.png"),
          title: "科技赋能 资源共享",
          main: "你拖我拉道路救援服务共享平台，提升共享科技应用水平，助力救援服务和科技应用降本增效，构建开放共赢的汽车救援共享新生态",
        },
        {
          src: require("../assets/img/image49.jpg"),
          title: "加入我们",
          main: "助力救援服务和科技应用降本增效 构建开放共赢的汽车救援共享新生态",
        },
        {
          src: require("../assets/img/image50.png"),
          title: "移动端",
          main: "C端小程序和救援技师端APP/小程序 亮点是实现业务共享 	解决个体救援技师业务过剩等问题",
        },
      ], //轮播内容
      arr: [
        {
          img: "https://dispatch-test.nituowola.com/img/xcx.jpg",
          title: "小程序",
        },
        {
          img: "https://dispatch-test.nituowola.com/img/app.png",
          title: "APP",
        },
        {
          img: "https://dispatch-test.nituowola.com/img/gzh.jpg",
          title: "公众号",
        },
      ], //右侧固定内容
      mainList: [
        {
          imgs: require("../assets/img/Frame4400.png"),
          img: require("../assets/img/Frame4400.png"),
          image: require("../assets/img/Frame4407.png"),
          title: "调度-智能",
        },
        {
          imgs: require("../assets/img/Frame4401.png"),
          img: require("../assets/img/Frame4401.png"),
          image: require("../assets/img/Frame4408.png"),
          title: "流程-标准",
        },
        {
          imgs: require("../assets/img/Frame4414.png"),
          img: require("../assets/img/Frame4414.png"),
          image: require("../assets/img/Frame4402.png"),
          title: "终端-加持",
        },
        {
          imgs: require("../assets/img/Frame4403.png"),
          img: require("../assets/img/Frame4403.png"),
          image: require("../assets/img/Frame4410.png"),
          title: "服务-优选",
        },
        {
          imgs: require("../assets/img/Frame4404.png"),
          img: require("../assets/img/Frame4404.png"),
          image: require("../assets/img/Frame4411.png"),
          title: "管控-实时",
        },
        {
          imgs: require("../assets/img/Frame4405.png"),
          img: require("../assets/img/Frame4405.png"),
          image: require("../assets/img/Frame4412.png"),
          title: "效率-突出",
        },
        {
          imgs: require("../assets/img/Frame4406.png"),
          img: require("../assets/img/Frame4406.png"),
          image: require("../assets/img/Frame4413.png"),
          title: "管理-专业",
        },
      ], //平台特色选项内容
      featureList: [
        {
          img: require("../assets/img/Frame4312.jpg"),
          title: "调度-智能",
          main: "海量数据分析 创建智慧调度",
        },
        {
          img: require("../assets/img/image28.png"),
          title: "流程-标准",
          main: "可视化服务流程 打造行业服务标杆",
        },
        {
          img: require("../assets/img/image29.png"),
          title: "终端-加持",
          main: "一机在手 万事无忧",
        },
        {
          img: require("../assets/img/image24.png"),
          title: "服务-优选",
          main: "高效规范的业务流程 完善的服务体系",
        },
        {
          img: require("../assets/img/image30.png"),
          title: "管控-实时",
          main: "全流程的数据化实时管理",
        },
        {
          img: require("../assets/img/Frame4344.png"),
          title: "效率-突出",
          main: "解决时效问题 提升服务效率",
        },
        {
          img: require("../assets/img/image32.png"),
          title: "管理-专业",
          main: "行业内多年的实体经验，权威的行业知识",
        },
      ], //平台特色内容
      num: "0",
      print: [
        {
          title: "拖车",
        },
        {
          title: "轿车托运",
        },
        {
          title: "搭电",
        },
        {
          title: "换胎",
        },
        {
          title: "快修",
        },
        {
          title: "送油",
        },
        {
          title: "地库",
        },
        {
          title: "困境",
        },
      ], //行业解决方案选项
      printList: [
        {
          img: require("../assets/img/Frame4295.jpg"),
          imgs: require("../assets/img/Group4271.png"),
          title: "拖车",
          main: "",
        },
        {
          img: require("../assets/img/Frame4302.jpg"),
          imgs: require("../assets/img/Group4272.png"),
          title: "轿车托运",
          main: "",
        },
        {
          img: require("../assets/img/Frame4296.jpg"),
          imgs: require("../assets/img/Frame4351.png"),
          title: "快修",
          main: "",
        },
        {
          img: require("../assets/img/Frame4300.jpg"),
          imgs: require("../assets/img/Frame4356.png"),
          title: "换胎",
          main: "",
        },
        {
          img: require("../assets/img/Frame4301.jpg"),
          imgs: require("../assets/img/Frame4352.png"),
          title: "搭电",
          main: "",
        },
        {
          img: require("../assets/img/Frame4297.png"),
          imgs: require("../assets/img/Frame4358.png"),
          title: "送油",
          main: "",
        },
        {
          img: require("../assets/img/Frame4298.jpg"),
          imgs: require("../assets/img/Frame4355.png"),
          title: "地库",
          main: "",
        },
        {
          img: require("../assets/img/Frame4299.png"),
          imgs: require("../assets/img/Frame4357.png"),
          title: "困境",
          main: "",
        },
      ], //行业解决方案内容
      teamworkList: [
        {
          img: require("../assets/img/image7.png"),
        },
        {
          img: require("../assets/img/image6.png"),
        },
        {
          img: require("../assets/img/image5.png"),
        },
        {
          img: require("../assets/img/image8.png"),
        },
        {
          img: require("../assets/img/image59.png"),
        },
        {
          img: require("../assets/img/image60.png"),
        },
        {
          img: require("../assets/img/image99.png"),
        },
        {
          img: require("../assets/img/image10.png"),
        },
        {
          img: require("../assets/img/Group4219.png"),
        },
        {
          img: require("../assets/img/image12.png"),
        },
        {
          img: require("../assets/img/image61.png"),
        },
        {
          img: require("../assets/img/image62.png"),
        },
      ], //合作伙伴列表
    };
  },

  created() {
    this.mainList[0].img = this.mainList[0].image;
  },

  methods: {
    mainTab(index) {
      console.log("点击了-----", index);
    },
    code(index) {
      this.mainList[this.oldindex].img = this.mainList[this.oldindex].imgs;
      this.mainList[index].img = this.mainList[index].image;
      this.oldindex = index;
    },
  },
};
</script>

<style scoped>
.el-carousel__item h3 {
  color: white;
  font-size: 0.8rem;
  margin: 0;
}
.el-carousel__item .blockMain {
  color: white;
  font-size: 0.8rem;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.block {
  width: 100%;
}
.small {
  width: 100%;
  height: 100%;
  position: relative;
}

.pic_item {
  position: relative;
  height: 100%;
}
.pic_item:hover {
  cursor: pointer;
}

.pic_item img {
  width: 100%;
  height: 100%;
}

.pic_item h3 {
  font-size: 60px;
  position: absolute;
  top: 150px;
  left: 90px;
}

.pic_item .blockMain {
  width: 771px;
  font-size: 30px;
  position: absolute;
  top: 285px;
  left: 90px;
}
/* 右侧二维码 */
.code {
  width: 128px;
  height: 510px;
  background-color: #fff;
  position: fixed;
  right: 0;
  bottom: 100px;
  text-align: center;
  border: 1px solid #f5f5f5;
  z-index: 1;
}
ul {
  display: block;
}
li {
  margin-top: 28px;
}
.codeImg {
  width: 80px;
  height: 80px;
}
.codeTitle {
  font-size: 24px;
  margin-top: 15px;
}
.mainNav {
  background-color: #fff;
}

.mainNavTitle {
  font-size: 50px;
  padding-top: 80px;
  padding-left: 72px;
}
.mainImg {
  width: 80px;
  height: 80px;
}
.mainTitle {
  font-size: 24px;
  padding-bottom: 15px;
}
.mainul,
.mainul1 {
  display: flex;
  margin-left: 72px;
}
.mainul li {
  text-align: center;
  margin-right: 77px;
  cursor: pointer;
  margin-bottom: 60px;
}

.mainul1 li {
  margin-right: 30px;
  cursor: pointer;
  margin-bottom: 30px;
}
.shade {
  width: 476px;
  height: 752px;
  background: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 0;
}
.feature {
  position: relative;
}
.featureImg {
  width: 1440px;
}

.feature1Title {
  font-size: 60px;
  color: #fff;
  position: absolute;
  left: 84px;
  top: 182px;
}
.feature1Main {
  width: 324px;
  font-size: 30px;
  color: #fff;
  position: absolute;
  top: 272px;
  left: 84px;
}
.active {
  color: #2c68ff;
  border-bottom: 4px solid #2c68ff;
}
.print {
  position: relative;
  height: 820px;
}
.printImg {
  width: 1440px;
}
.printBox {
  text-align: center;
  width: 234px;
  height: 234px;
  box-sizing: border-box;
  background-color: #fff;
  position: absolute;
  top: 60px;
  left: 69px;
}
.anTitle {
  height: 30px;
}
.model {
  color: #2c68ff;
  border-bottom: 1px solid #2c68ff;
}
.printIcon {
  width: 80px;
  height: 80px;
  margin-top: 60px;
}
.printTit {
  color: #2c68ff;
  font-size: 24px;
}
.printMain {
  width: 324px;
  font-size: 18px;
  color: #32333c;
  margin-top: 20px;
  margin-left: 72px;
}

.join {
  /* width: 1440px; */
  height: 640px;
  background-image: url("../assets/img/bei.jpg");
  background-size: 1440px;
  box-sizing: border-box;
}
.joinTitle {
  font-size: 50px;
  color: #fff;
  padding-top: 114px;
  margin-left: 72px;
}
.btn {
  display: inline-block;
  text-align: center;
  line-height: 82px;
  width: 217px;
  height: 82px;
  background-color: #2c68ff;
  color: #fff;
  font-size: 28px;
  margin-top: 136px;
  margin-left: 72px;
}

.teamwork {
  height: 427px;
  background-color: #f5f8ff;
}
.teamwork ul {
  margin-left: 72px;
}
.teamwork ul li {
  width: 199px;
  float: left;
  margin-right: 16px;
}
.teamworkTitle {
  display: inline-block;
  font-size: 28px;
  margin-top: 80px;
  margin-left: 72px;
}
</style>
